/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { Box, Container } from '@material-ui/core';
import styles from './Campaign.module.scss';

export default function Campaign() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Box className={styles.card}>
          <Box>
            <h2 className={styles.title}>
              ペイディ加盟店手数料1か月無料キャンペーン
            </h2>
            <p className={styles.text}>
              対象期間中にネットショップ支援室経由でペイディを新規導入すると、
              <br />
              「2024年7月分」の加盟店手数料が無料となります。
            </p>
            <p className={styles.head}>エントリー方法</p>
            <p className={styles.textBottom}>
              以下URLよりペイディを申し込む
              <br />
              <a
                href="https://paidy.com/merchant/application/#documents"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://paidy.com/merchant/application/#documents
              </a>
            </p>
          </Box>
        </Box>

        <Box className={styles.conditions}>
          <Box>
            <Box className={styles.condition}>
              <p className={styles.title}>対象期間</p>
              <Box className={styles.content}>
                <p className={styles.text}>
                  2024年5月1日0:00~2024年6月30日23:59に新規申込 <br />
                  <br />
                  <b>対象</b>
                  <br />
                  上記期間に申込した全加盟店
                </p>
              </Box>
            </Box>

            <Box className={styles.condition} mt={4}>
              <p className={styles.title}>無料適用期間</p>
              <Box className={styles.content}>
                <p className={styles.text}>
                  2024年7月
                  <br />
                  <br />
                  <b>加盟店手数料無料の上限金額</b>
                  <br />
                  5万円
                </p>
              </Box>
            </Box>
          </Box>

          <Box className={styles.condition}>
            <p className={styles.title}>ご利用料金とキャンペーン特典</p>
            <Box className={styles.content}>
              <p className={styles.text}>
                <b>キャンペーン期間中</b>
                <br />
                初期費用0円 <br />
                 月額費用 0円
                <br />
                 固定費用0円
                <br />
                 決済手数料（加盟店手数料）物販0%
              </p>
              <p className={styles.note}>
                ※6回、12回払いご利用加盟店はキャンペーン適用外となります
              </p>
            </Box>
            <Box className={styles.content}>
              <p className={styles.text}>
                <b>キャンペーン終了後</b>
                <br />
                初期費用0円
                <br />
                 月額費用 0円
                <br />
                 固定費用0円
                <br />
                 決済手数料（加盟店手数料）物販3.5%
              </p>
              {/* <p className={styles.note}>
                ※6回あと払い、12回あと払いについては別途お申込が必要となります。
              </p> */}
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
