import { Box, Container, Divider, Hidden } from '@material-ui/core';
import React from 'react';
import ImgTimeLine from 'src/images/campaign/merchant/netshopshienshitsu/time-line.png';
import ImgTimeLineSp from 'src/images/campaign/merchant/netshopshienshitsu/time-line-sp.png';
import styles from './Cycle.module.scss';

export default function Cycle() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Box display="flex" flexDirection={'column'} alignItems={'center'}>
          <h1 className={styles.title}>加盟店支払いサイクル</h1>
          <Hidden xsDown>
            <p className={styles.title2}>
              清算サイクルは月1回、月末締めの翌月20日払い。
              <br />
              3・6・12回あと払いでも翌月に一括支払いします。
            </p>
          </Hidden>
          <Hidden smUp>
            <p className={styles.title2}>
              清算サイクルは月1回、月末締めの翌月20日払い。3回あと払いでも翌月に一括支払いします。
            </p>
          </Hidden>
          <Hidden xsDown>
            <img
              className={styles.timeLine}
              width={879}
              height={406}
              alt="time-line"
              src={ImgTimeLine}
            />
          </Hidden>
          <Hidden smUp>
            <img
              className={styles.timeLine}
              width={316}
              alt="time-line"
              src={ImgTimeLineSp}
            />
          </Hidden>
          <Box className={styles.blockBottom}>
            <Divider />
            <Box mt={2} />
            <p className={styles.note}>
              *5：口座振替は毎月27日。金融機関休業日の場合は翌営業日。
              <br />
              *6：上記は標準契約の例です。現行のご契約の翌月払いと同日に「3・6・12回あと払い」の立替代金もお振込みします。{' '}
            </p>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
