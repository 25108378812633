import React from 'react';
import { Layout } from 'src/components';
import { SITE_METADATA } from 'src/constants';
import TopBanner from 'src/components/Campaign/merchant/netshopshienshitsu/TopBanner';
import Campaign from 'src/components/Campaign/merchant/netshopshienshitsu/Campaign';
import Merits from 'src/components/Campaign/merchant/netshopshienshitsu/Merits';
import WhatIsPaidy from 'src/components/Campaign/merchant/netshopshienshitsu/WhatIsPaidy';
import NPay from 'src/components/Campaign/merchant/netshopshienshitsu/NPay';
import HowToPay from 'src/components/Campaign/merchant/netshopshienshitsu/HowToPay';
import Cycle from 'src/components/Campaign/merchant/netshopshienshitsu/Cycle';
import Application from 'src/components/Campaign/merchant/netshopshienshitsu/Application';
import styles from './202405_netshopshienshitsu.module.scss';

const SEOProps = {
  title: SITE_METADATA.netshopshienshitsu.title,
  description: SITE_METADATA.netshopshienshitsu.description,
};

export default function Netshopshienshitsu() {
  return (
    <Layout hasNoHeader hasNoFooter SEOProps={SEOProps}>
      <TopBanner />
      <Campaign />
      <Merits />
      <WhatIsPaidy />
      <NPay />
      <HowToPay />
      <Cycle />
      <Application />
      <div className={styles.footer} />
    </Layout>
  );
}
