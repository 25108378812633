import { Box, Container, Hidden } from '@material-ui/core';
import React from 'react';
import ImgGraph from 'src/images/campaign/merchant/atobaraisodanshitsu/graph.png';
import styles from './WhatIsPaidy.module.scss';

const Title = () => (
  <h1 className={styles.title}>
    ｢決済方法はクレカだけで十分｣と
    <br />
    思っていませんか？
  </h1>
);

export default function WhatIsPaidy() {
  return (
    <section className={styles.section}>
      <Container>
        <Box m="auto">
          <Hidden xsDown>
            <Title />
          </Hidden>
          <Box className={styles.block}>
            <img
              alt="graph"
              width={394}
              height={283}
              src={ImgGraph}
              className={styles.graph}
            />
            <Hidden smUp>
              <Title />
            </Hidden>
            <Box>
              <p className={styles.text}>
                不正利用や情報漏洩などのセキュリティ面が不安で「クレジットカードは使いたくない」というユーザーは意外に多く、ECサイトでの購入者の約半数はクレジットカード以外を選択。
                <br />
                <br />
                こうしたユーザーにとって、魅力的な決済方法となっているのがペイディです。携帯電話番号とメールアドレスを入力するだけで支払いできるので、ユーザーが提供する情報が少なく、安心感をもってお買い物を楽しむことができます。
                <br />
                <br />
                ユーザーの使いやすい決済手段をショップに準備しておくことで、カゴ落ちを防いで、顧客層を拡大、売上アップを狙うことが可能です。
              </p>
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
