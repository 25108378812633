import { Box, Container, Hidden } from '@material-ui/core';
import React from 'react';
import LinearCTAButton from 'src/components/LinearCTAButton';
import ImgTopBanner from 'src/images/campaign/merchant/netshopshienshitsu/top-banner.png';
import ImgLogoHeader from 'src/images/logo-color-header.png';
import styles from './TopBanner.module.scss';

const Text = () => (
  <>
    <ul className={styles.ul}>
      <li>
        非クレカ層や若年層などの新規ユー
        <Hidden smUp>
          <br />
        </Hidden>
        ザーを獲得
      </li>
      <li>シンプルなUIでカゴ落ちを防止</li>
      <li>決済から購入代金支払いまでデジタル完結・コンビニ払いも可能</li>
      <li>もちろん債権は100％保証</li>
    </ul>
    <h2 className={styles.h2}>
      分割手数料無料*の3・6・12回あと払いで､
      <br />
      カゴ単価200％増も！
    </h2>
    <p className={styles.p}>*口座振替・銀行振込のみ無料</p>
    <LinearCTAButton
      text="今すぐ申し込む"
      className={styles.btn}
      to="https://paidy.com/merchant/application/?channel=cpatbri#documents"
    />
  </>
);

export default function TopBanner() {
  return (
    <>
      <section className={styles.section}>
        <Container>
          <Box display="flex" flexWrap="wrap" className={styles.row}>
            <Box className={styles.logoContainer}>
              <img alt="logo" src={ImgLogoHeader} width={142} height={56} />
              <h1 className={styles.h1}>
                デジタル完結型
                <br />
                あと払い決済「ペイディ」
              </h1>
              <Hidden xsDown>
                <Text />
              </Hidden>
            </Box>
            <Box display="flex" alignItems="flex-end">
              <img
                alt="top-banner"
                width={582}
                height={502}
                src={ImgTopBanner}
                className={styles.banner}
              />
            </Box>
          </Box>
        </Container>
      </section>
      <Hidden smUp>
        <Box className={styles.background2}>
          <Text />
        </Box>
      </Hidden>
    </>
  );
}
