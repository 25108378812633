import { Box, Container, Hidden } from '@material-ui/core';
import React from 'react';
import Steps from 'src/components/Apple/Steps';
import StepsMobile from 'src/components/Apple/StepsMobile';
import LinearCTAButton from 'src/components/LinearCTAButton';
import ImgApplication0 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-0.png';
import ImgApplication1 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-1.png';
import ImgApplication2 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-2.png';
import ImgApplication3 from 'src/images/campaign/merchant/atobaraisodanshitsu/application-3.png';
import styles from './Application.module.scss';

const DATA = [
  {
    image: ImgApplication0,
    title: 'Webお申し込み',
    info: 'フォームより、事業内容や金融機関などの必要情報を入力してください。',
  },
  {
    image: ImgApplication1,
    title: '加盟店審査',
    info: '追加情報が必要な場合がございます。',
  },
  {
    image: ImgApplication2,
    title: '審査結果をメールでご連絡',
    info: '10営業日以内(目安)にご連絡いたします。',
  },
  {
    image: ImgApplication3,
    title: 'ご利用スタート',
    info: '初期設定が完了したらご利用開始となります。',
  },
];

export default function Application() {
  return (
    <section className={styles.section}>
      <Container>
        <Box display="flex" alignItems={'center'} flexDirection={'column'}>
          <Hidden xsDown>
            <Steps
              columnWidth={221}
              title={
                <span className={styles.title}>
                  登記簿や印鑑証明の提出不要。
                  <br />
                  お申し込みはウェブで完結、<b>最短1週間</b>でご利用開始。
                </span>
              }
              steps={DATA}
            />
          </Hidden>
          <Hidden smUp>
            <StepsMobile
              textAlign="left"
              columnWidth={310}
              title={
                <>
                  登記簿や印鑑証明の提出不要。
                  <br />
                  お申し込みはウェブで完結、<b>最短1週間</b>でご利用開始。
                </>
              }
              steps={DATA}
            />
          </Hidden>
          <p className={styles.text}>
            お申し込みや導入に関して、ご不明な点がございましたらお気軽にご連絡ください。お客さまの業種に合わせた専門の担当者がお答えします。
          </p>
          <LinearCTAButton
            text="お気軽にご相談ください"
            className={styles.btn}
            to="https://paidy.com/merchant/contact_us/"
          />
        </Box>
      </Container>
    </section>
  );
}
