import { Box, Container } from '@material-ui/core';
import React from 'react';
import ImgPay0 from 'src/images/campaign/merchant/atobaraisodanshitsu/pay-0.png';
import ImgPay1 from 'src/images/campaign/merchant/atobaraisodanshitsu/pay-1.png';
import ImgPay2 from 'src/images/campaign/merchant/atobaraisodanshitsu/pay-2.png';
import styles from './HowToPay.module.scss';

const DATA = [
  {
    img: ImgPay0,
    title: 'スマホだけで今すぐ購入',
    text:
      'メアドと携帯番号を入力して、SMSで届く４桁の認証コードを入力するだけで、お買い物ができます。',
  },
  {
    img: ImgPay1,
    title: 'お支払いはまとめて翌月',
    text:
      '毎月のご利用分を、翌月27日までにコンビニ・銀行振込・口座振替でまとめてお支払い。コンビニ支払いならレジでアプリを見せるだけ。',
  },
  {
    img: ImgPay2,
    title: (
      <>
        3・6・12回あと払いが
        <br />
        分割手数料無料*
      </>
    ),
    text: (
      <>
        ペイディアプリから本人確認をすると、分割手数料無料*の3・６・12回あと払いが使えます。
        <br />
        <span className={styles.note}>*口座振込・銀行振込のみ無料</span>
      </>
    ),
  },
];

export default function HowToPay() {
  return (
    <section className={styles.section}>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <h1 className={styles.title}>あと払いペイディとは</h1>
          <p className={styles.title2}>
            クレジットカード不要、スマホだけで今すぐ購入。お支払い翌月でOK。
            <br />
            複数回の決済もまとめて翌月お支払い。
          </p>

          <Box className={styles.container}>
            {DATA.map((item) => (
              <Box className={styles.item} key={item.title}>
                <img alt={item.title} width={184} height={184} src={item.img} />
                <p className={styles.title}>{item.title}</p>
                <p className={styles.text}>{item.text}</p>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </section>
  );
}
