import { Box, Container } from '@material-ui/core';
import React from 'react';
import ImgMerit0 from 'src/images/campaign/merchant/atobaraisodanshitsu/merit-0.png';
import ImgMerit1 from 'src/images/campaign/merchant/atobaraisodanshitsu/merit-1.png';
import ImgMerit2 from 'src/images/campaign/merchant/atobaraisodanshitsu/merit-2.png';
import ImgMerit3 from 'src/images/campaign/merchant/atobaraisodanshitsu/merit-3.png';
import styles from './Merits.module.scss';

const DATA = [
  {
    img: ImgMerit0,
    title: (
      <>
        0円でスタート
        <br />
        決済手数料3.5%
      </>
    ),
    text: (
      <>
        月額費用0円。
        <br />
        ※3回あと払い利用時<b>4.5％</b>。
      </>
    ),
  },
  {
    img: ImgMerit1,
    title: '余計な業務なし',
    text:
      'ユーザーへの請求はPaidy社から直接SMS/メールで実施。払込票の印刷・発送は必要なし。',
  },
  {
    img: ImgMerit2,
    title: '売上金100％保証',
    text: '未払い金はPaidyが負担、加盟店様のリスクなし。',
  },
  {
    img: ImgMerit3,
    title: '客単価向上に貢献',
    text: 'ユーザーは1回でも3回分割払い（分割手数料無料）でも支払い可能。',
  },
];

export default function Merits({ data = DATA }) {
  return (
    <section className={styles.section}>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <h3 className={styles.title}>導入のメリット</h3>
          <Box className={styles.container}>
            {data.map((item) => (
              <Box key={item.title} className={styles.item}>
                <img alt={item.title} width={128} height={128} src={item.img} />
                <p className={styles.title}>{item.title}</p>
                <p className={styles.text}>{item.text}</p>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </section>
  );
}
